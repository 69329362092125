<template>
    <v-form ref="form1" style="width: 100%" v-model="form1Valid" lazy-validation>
        <v-row>
            <v-col class="pb-0">
                <v-select :items="items" v-model="field" item-text="label" return-object
                          label="Campo" :rules="requiredRules">
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="">
                <v-radio-group v-model="ascending" class="ma-0" hide-details>
                    <v-radio label="Crescente" :value="true" />
                    <v-radio label="Descrescente" :value="false" />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="text-right">
                    <v-btn class="mr-2" text @click="cancel">Cancelar</v-btn>
                    <v-btn color="primary" text @click="ok">Aplicar</v-btn>
                </div>
            </v-col>
        </v-row>
    </v-form>

</template>

<script>
    export default {
        name: 'SortForm',
        props: {
            value: null,
            opened: false,
        },
        watch: {
            opened(value) {
                if (value) {
                    this.field = this.value.data.field;
                    this.ascending = this.value.data.ascending;
                }
            },
        },
        data() {
            return {
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                ascending: this.value.data.ascending,
                items: [
                    {
                        label: 'Mutuario',
                        name: 'mutuario',
                    },
                    /*{
                        label: 'Nº Solicitação',
                        name: 'solicitacao',
                    },*/
                    {
                        label: 'Data Fiscalização',
                        name: 'data-fiscalizacao',
                    },{
                        label: 'Data Limite',
                        name: 'data-limite',
                    },
                ],
                field: this.value.data.field,
            };
        },
        methods: {
            ok() {
                if (this.$refs.form1.validate()) {
                    const response = this._.cloneDeep(this.value);
                    response.data.field = this._.cloneDeep(this.field);
                    response.data.ascending = this.ascending;

                    this.$emit('input', response);
                    this.close();
                }
            },
            cancel() {
                this.$refs.form1.reset();
                this.$emit('canceled');
                this.close();
            },
            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>

<style scoped>

</style>