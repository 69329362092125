import {Cidade} from '@/models/Cidade';

export class Localizacao {
    public endereco: string;
    public numero: string;
    public complemento: string;
    public bairro: string;
    public cep: string;
    public cidade: Cidade = new Cidade();
}
