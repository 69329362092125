import {Pessoa} from '@/models/Pessoa';
import {Agencia} from '@/models/Agencia';
import {Empreendimento} from '@/models/Empreendimento';
import {ObjetoFinanciado} from '@/models/ObjetoFinanciado';
import {FinanciamentoGarantia} from '@/models/FinanciamentoGarantia';
import {Area} from '@/models/Area';
import {Finalidade} from '@/models/Finalidade';
import {OrigemRecurso} from '@/models/OrigemRecurso';

export class Financiamento {
    public operacao: string;
    public operacaoDigito: string;
    public cedula: string;
    public dataCedula: string = new Date().toLocaleDateString();
    public dataVencimento: string = new Date().toLocaleDateString();
    public mutuario: string;
    public agencia: Agencia = new Agencia();
    public finalidade: Finalidade = new Finalidade();
    public valor: number = 0;
    public valorRecursosProprios: number = 0;
    public valorTotalGarantias: number = 0;
    public empreendimento: Empreendimento = new Empreendimento();
    public origemRecursos: OrigemRecurso = new OrigemRecurso();
    public objetos: ObjetoFinanciado[] = [];
    public garantias: FinanciamentoGarantia[] = [];
    public area: Area = new Area();
    public localGarantias: string;
}
