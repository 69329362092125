import gql from 'graphql-tag';

export class EmpreendimentoQueries {
    public static getAll = gql`query {
        empreendimentos{
            id
            codigo
            descricao
        }
    }`;
}
