import gql from 'graphql-tag';

export class OrigemRecusoQueries {
    public static getAll = gql`query {
        origemRecursos{
            id
            codigo
            descricao
        }
    }`;
}
