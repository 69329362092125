<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-card-title>
                <span class="headline">Nova Finalidade</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formFinalidade" v-model="formFinalidade" lazy-validation>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="finalidade.codigo" label="Código"
                                          placeholder=" " v-mask="'###'"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field v-model="finalidade.descricao" label="Descrição"
                                          placeholder=" "
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="cancel">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'FinalidadeDialog',
        data() {
            return {
                dialog: false,
                finalidade: {
                    codigo: null,
                    descricao: null,
                },
                formFinalidade: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
            };
        },
        methods: {
            openDialog() {
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
                this.finalidade = {codigo: null, descricao: null};
            },
            resetForm() {
                this.$refs.formFinalidade.reset();
            },
            save() {
                if (this.$refs.formFinalidade.validate()) {
                    this.$emit('saved', Object.assign({}, this.finalidade));
                    this.resetForm();
                    this.closeDialog();
                }

            },
            cancel() {
                this.resetForm();
                this.closeDialog();
            },
        },
    };
</script>

<style scoped>

</style>
