<template>
    <v-form ref="form1" style="width: 100%" v-model="form1Valid" lazy-validation>
        <v-autocomplete v-model="empreendimento" label="Empreendimento" placeholder=" "
                        :items="empreendimentos" item-text="descricao"
                        auto-select-first return-object
                        hide-no-data :rules="requiredRules">
            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title>{{data.item.descricao}}</v-list-item-title>
                </v-list-item-content>
            </template>
        </v-autocomplete>
        <div class="text-right">
            <v-btn class="mr-2" text @click="cancel">Cancelar</v-btn>
            <v-btn color="primary" text @click="ok">Aplicar</v-btn>
        </div>
    </v-form>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "EmpreendimentoForm",
    props: {
        value: null,
        opened: null,
    },
    watch: {
        opened(value) {
            if (value) {
                this.getEmpreendimentos();
                this.empreendimento = this.value.data.id ? this.value.data : null;
            }
        },
    },
    data() {
        return {
            form1Valid: true,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            empreendimento: this.value.data.id ? this.value.data : null,
            empreendimentos: null,
        };
    },
    methods: {
        ok() {
            if (this.$refs.form1.validate()) {
                const response = this._.cloneDeep(this.value);
                response.data.id = this.empreendimento.id;
                response.data.nome = this.empreendimento.descricao;
                this.$emit('input', response);
                this.close();
            }
        },
        cancel() {
            this.$refs.form1.reset();
            this.$emit('canceled');
            this.close();
        },
        close() {
            this.$emit('update:opened', false);
        },
        getEmpreendimentos() {
            this.$apollo.query({
                query: gql`query {
                        empreendimentos{
                            id
                            descricao
                        }
                    }`
            }).then((result) => {
                this.empreendimentos = result.data.empreendimentos;
            });
        },
    },
    mounted() {
        this.getEmpreendimentos();
    },
}
</script>

<style scoped>

</style>