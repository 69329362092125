<template>
    <page title="Fiscalizações">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div slot="menu">
            <v-menu offset-y >
                <template v-slot:activator="{ on }">
                    <v-btn outlined style="border: solid thin rgba(0, 0, 0, 0.12)" v-on="on">
                        <v-icon left>mdi-cog</v-icon>
                        <span>Ações</span>
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="openEnviarBatchEmailDialog" v-if="$parent.$parent.usuario.admin || currentUserId === 14">
                        <v-list-item-icon>
                            <v-icon>mdi-email-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Enviar emails</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="downloadResultadoXls" :disabled="downloadingResumo">
                        <v-list-item-icon>
                            <v-icon>mdi-database-export</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Exportar resultado</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn v-if="$parent.$parent.usuario.admin || $parent.$parent.usuario.id === 14"
                   class="ml-2" text outlined :to="{name:'servicos'}">Serviços</v-btn>
        </div>
        <filter-list-fragment v-model="currentFilter" @input="onFilterChanged" />

        <div class="mt-2" v-if="totalCount > 0">
            <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
        </div>

        <div class="text-center" v-if="fiscalizacoes">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>

        <v-card class="mt-3">

            <v-list class="fiscalizacoes py-0" :three-line="$vuetify.breakpoint.smAndUp" :two-line="$vuetify.breakpoint.xsOnly" v-if="fiscalizacoes && fiscalizacoes.length > 0">
                    <template v-for="(fiscalizacao, index) in fiscalizacoes">
                        <v-list-item :dense="$vuetify.breakpoint.xsOnly" :key="fiscalizacao.id" :class="getFinalizadoClass(fiscalizacao)"  :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" >

                            <v-list-item-avatar>
                                <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-if="!$vuetify.breakpoint.xsOnly" v-html="highlight(fiscalizacao.financiamento.mutuario, currentFilter.searchValue)"></v-list-item-title>
                                <v-list-item-title v-else class="body-2" v-html="highlight(fiscalizacao.financiamento.mutuario, currentFilter.searchValue)"></v-list-item-title>
                                <v-list-item-subtitle v-if="!$vuetify.breakpoint.xsOnly" v-html="'N. Solicitação: ' + highlight(fiscalizacao.numeroSolicitacao, currentFilter.searchValue) + '&emsp;Operação: ' + highlight(fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : ''), currentFilter.searchValue)" >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else class="caption" style="line-height: 10px" v-html="'N. Sol.: ' + highlight(fiscalizacao.numeroSolicitacao, currentFilter.searchValue) + '&emsp;Op.: ' + highlight(fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : ''), currentFilter.searchValue)" >
                                </v-list-item-subtitle>
                                <div>
                                    <v-tooltip bottom v-if="fiscalizacao.emailEnviadoAt">
                                        <template v-slot:activator="{ on }">
                                            <v-chip v-on="on" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                                EMAIL ENVIADO
                                            </v-chip>
                                        </template>
                                        <span>{{fiscalizacao.emailEnviadoAt | moment('DD MMM YYYY HH:mm [hrs]')}}</span>
                                    </v-tooltip>

                                    <v-chip v-if="fiscalizacao.fotos.length > 0" x-small label color="orange" text-color="white" class="mr-1 font-weight-bold px-2">
                                        <span>{{fiscalizacao.fotos.length}} {{fiscalizacao.fotos.length > 1 ? "FOTOS" : "FOTO"}}</span>
                                    </v-chip>

                                    <v-chip v-if="fiscalizacao.arquivos.length > 0" x-small label color="teal lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                        <span>{{fiscalizacao.arquivos.length}} {{fiscalizacao.arquivos.length > 1 ? "ANEXOS" : "ANEXO"}}</span>
                                    </v-chip>

                                    <v-chip v-if="fiscalizacao.contatos.length > 0" x-small label color="blue-grey darken-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                        <span>{{fiscalizacao.contatos.length}} {{fiscalizacao.contatos.length > 1 ? "CONTATOS" : "CONTATO"}}</span>
                                    </v-chip>

                                    <v-chip v-if="fiscalizacao.lembretes.length > 0" x-small label color="yellow" text-color="" class="mr-1 font-weight-medium px-2">
                                        <span>{{fiscalizacao.lembretes.length}} {{fiscalizacao.lembretes.length > 1 ? "LEMBRETES" : "LEMBRETE"}}</span>
                                    </v-chip>

                                </div>
                            </v-list-item-content>

                            <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly">
                                <v-list-item-action-text >Data Limite: <b>{{new Date(fiscalizacao.dataLimite) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                                <v-list-item-action-text v-if="fiscalizacao.dataFiscalizacao">Data Fiscalização: <b>{{new Date(fiscalizacao.dataFiscalizacao) | moment("DD MMM YYYY")}}</b></v-list-item-action-text>
                            </v-list-item-action>
                            <div v-if="fiscalizacao.dataCancelamento" class="d-flex justify-center align-center"
                                 style="position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: repeating-linear-gradient(45deg,transparent,transparent 30px,rgba(255,0,0,0.1) 1px, rgba(255,0,0,0.1) 60px
  )">
                                <span class="text-h4 font-weight-black">CANCELADO</span>
                            </div>
                        </v-list-item>

                        <v-divider ></v-divider>
                    </template>
            </v-list>
            <div v-else-if="loading === false && (fiscalizacoes === null || fiscalizacoes.length === 0)" class="text-center pa-6">
                <h1 class="display-3 mb-4">Ops!</h1>
                <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
            </div>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading"
                               absolute top color="secondary" />
        </v-card>

        <div class="text-center mt-3" v-if="fiscalizacoes">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                            @input="pageChanged"></v-pagination>
        </div>

        <!--<v-btn fixed dark fab large bottom right color="secondary" @click="openNewDialog()">
            <v-icon>mdi-plus</v-icon>
        </v-btn>-->

        <new-dialog ref="newDialog" @saved="onFiscalizacaoSaved" />

        <v-dialog v-model="batch.dialog" persistent max-width="400px">
            <v-card v-if="batch.dialog">
                <v-card-text>
                    <v-container>
                        <div v-if="batch.uploading" class="mt-6">
                            <v-progress-circular
                                    indeterminate
                                    color="primary"
                            ></v-progress-circular>
                            <span class="ml-3 subtitle-1">Processando arquivo ...</span>
                        </div>
                        <div v-if="batch.success">
                            <v-alert type="success" dense text>
                                Arquivo processado com sucesso!
                            </v-alert>
                            <p class="mb-0 subtitle-1">Registros totais:
                                <span class="font-weight-bold">{{batch.result.totalRegistros}}</span></p>
                            <p class="mb-0 subtitle-1">Registros inseridos:
                                <span class="font-weight-bold">{{batch.result.totalInseridos}}</span></p>
                            <p class="mb-0 subtitle-1">Registros existentes:
                                <span class="font-weight-bold">{{batch.result.totalExistentes}}</span></p>
                            <p class="mb-0 subtitle-1">Registros falhos:
                                <span class="font-weight-bold">{{batch.result.totalFalhos}}</span></p>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <div class="pa-3" style="width: 100%">
                        <div class="float-right">
                            <v-btn color="primary"
                                   @click="closeBatchDialog"
                                   :disabled="batch.uploading">OK</v-btn>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <enviar-batch-email-dialog ref="enviarBatchEmailDialog" @completed="refreshList" @canceled="refreshList" />
    </page>
</template>

<script>
    // @ts-ignore
    import page from '../../../components/Page';
    // @ts-ignore
    import newDialog from './NewDialog';
    import {FiscalizacaoQueries} from '../../../queries/FiscalizacaoQueries';
    // @ts-ignore
    import FilterListFragment from './fragments/filter/FilterListFragment';
    import localforage from 'localforage';
    import {UserData} from '../../../models/filter/UserData';
    import {IdData} from '../../../models/filter/IdData';
    import {BooleanData} from '../../../models/filter/BooleanData';
    import {DateData} from '../../../models/filter/DateData';
    import {SortData} from '../../../models/filter/SortData';
    import {getIconByFiscalizacaoTipo, getFinalizadoClass} from '../../../assets/FiscaizacaoUtil'
    // @ts-ignore
    import EnviarBatchEmailDialog from "./EnviarBatchEmailDialog";
    import {AuthService} from "../../../service/AuthService";
    import EnviarLaudosBancoDialog from "./EnviarLaudosBancoDialog";
    export default {
        name: 'Index',
        components: {
            EnviarLaudosBancoDialog,
            EnviarBatchEmailDialog,
            FilterListFragment,
            page,
            newDialog,
        },
        data() {
            return {
                offset: 10,
                page: 1,
                totalCount: 0,
                fiscalizacoes: [],
                currentFilter: {},
                loading: true,
                downloadingResumo: false,
                batch: {
                    dialog: false,
                    uploading: false,
                    success: false,
                    result: null
                },
                CURRENT_USER: null,
            };
        },
        computed:{
            currentUserId(){
                return this.CURRENT_USER?.id;
            }
        },
        watch: {
            $route(to, from) {
                if(to.query.page === undefined){
                    this.$router.push({query:{page: '1'}});
                    return;
                }
                this.page = parseInt(to.query.page);
                if(to.query.page === from.query.page){
                    return
                }

                this.getList(this.currentFilter)
            }
        },
        methods: {
            openNewDialog() {
                this.$refs.newDialog.openDialog();
            },
            openEnviarBatchEmailDialog(){
                this.$refs.enviarBatchEmailDialog.openDialog(this.formatFilterToRequest(this.currentFilter));
            },
            downloadResultadoXls(){
                this.downloadingResumo = true;
                this.$axios.post(
                    '/fiscalizacoes/resultado_filtro/xls',
                    this.formatFilterToRequest(this.currentFilter),
                    {responseType: 'blob'})
                    .then((response) => {
                        const fileLink = document.createElement('a');
                        fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                        const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                        fileLink.setAttribute('download', fileName);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                        this.downloadingResumo = false;
                    }).catch(() => {
                        this.$dialog.error({
                            text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                            title: 'Ops',
                        });
                        this.downloadingResumo = false;
                    })
            },




            pageChanged(value){
                this.$router.push({query:{page:this.page}});
            },
            getList(filter) {
                this.loading = true;

                this.$apollo.query({
                    query: FiscalizacaoQueries.search,
                    variables: {
                        filter: this.formatFilterToRequest(filter),
                        page: this.page - 1,
                        offset: this.offset
                    },
                }).then((result) => {
                    this.fiscalizacoes = result.data.searchFiscalizacoes.fiscalizacoes;
                    this.totalCount = result.data.searchFiscalizacoes.totalCount;
                    this.loading = false;
                });
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
            getFinalizadoClass(fiscalizacao){
                return getFinalizadoClass(fiscalizacao);
            },
            onFilterChanged(filter) {
                this.currentFilter = filter;
                this.saveFilter(filter);
                if(this.$route.query.page == 1){
                    this.getList(this.currentFilter);
                    return
                }
                this.$router.replace({query:{page:1}});
            },
            onFiscalizacaoSaved(fiscalizacao) {
                this.fiscalizacoes.unshift(fiscalizacao);
            },
            formatFilterToRequest(filter) {
                const parsedFilter = this._.cloneDeep(filter);

                if (!parsedFilter.searchValue) {
                    parsedFilter.searchCriteria = null;
                }

                if (filter.itens) {
                    parsedFilter.itens = filter.itens.map((filterItem) => {
                        const filterItemMapped = {
                            name: filterItem.name,
                        };

                        switch (filterItem.type) {
                            case 'boolean':
                                filterItemMapped.booleanData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.booleanData.trueLabel;
                                delete filterItemMapped.booleanData.falseLabel;
                                delete filterItemMapped.booleanData.toLabel;

                                break;
                            case 'sort':
                                filterItemMapped.sortData = this._.cloneDeep(filterItem.data);
                                filterItemMapped.sortData.fieldName = filterItemMapped.sortData.field.name;
                                delete filterItemMapped.sortData.field;
                                delete filterItemMapped.sortData.toLabel;
                                break;
                            case 'user':
                                filterItemMapped.userData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.userData.nome;
                                delete filterItemMapped.userData.sobrenome;
                                delete filterItemMapped.userData.email;
                                delete filterItemMapped.userData.imagem;
                                delete filterItemMapped.userData.toLabel;
                                break;
                            case 'tipo':
                                filterItemMapped.idData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.idData.nome;
                                delete filterItemMapped.idData.toLabel;
                                break;
                            case 'empreendimento':
                                filterItemMapped.idData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.idData.nome;
                                delete filterItemMapped.idData.toLabel;
                                break;
                            case 'date':
                                filterItemMapped.dateData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.dateData.rangeType;
                                delete filterItemMapped.dateData.toLabel;
                        }

                        return filterItemMapped;
                    });
                }

                return parsedFilter;
            },
            saveFilter(filter) {
                const filterParsed = this._.cloneDeep(filter);
                filterParsed.searchCriteria = null;
                filterParsed.searchValue = null;

                localforage.setItem('fiscalizacoes_filter', filterParsed);
            },
            async loadFilter() {
                const savedFilter = await localforage.getItem('fiscalizacoes_filter');
                if (savedFilter) {
                    savedFilter.itens.map((filterItem) => {
                        switch (filterItem.type) {
                            case 'user':
                                filterItem.data = new UserData(
                                    filterItem.data.id, filterItem.data.nome, filterItem.data.sobrenome,
                                    filterItem.data.imagem);
                                break;
                            case 'tipo':
                                filterItem.data = new IdData(filterItem.data.id, filterItem.data.nome);
                                break;
                            case 'empreendimento':
                                filterItem.data = new IdData(filterItem.data.id, filterItem.data.nome);
                                break;
                            case 'boolean':
                                filterItem.data = new BooleanData(
                                    filterItem.data.status, filterItem.data.trueLabel, filterItem.data.falseLabel);
                                break;
                            case 'date':
                                filterItem.data = new DateData(filterItem.data.before, filterItem.data.after, filterItem.data.rangeType);
                                break;
                            case 'sort':
                                filterItem.data = new SortData(
                                    filterItem.data.field.label, filterItem.data.field.name, filterItem.data.ascending);
                                break;
                        }
                        return filterItem;
                    });
                }
                return savedFilter;
            },
            closeBatchDialog(){
                this.batch.dialog = false;
                this.batch.success = false;
                this.batch.result = null;
                this.refreshList();
            },
            refreshList(){
                this.loading = true;
                this.getList(this.currentFilter);
            },
            highlight: function(words, query){
                if(query === ''){
                    return words;
                }
                return words.toString().replace(new RegExp(query, "gi"), matchedTxt => {
                    return '<span class="highlight">' + matchedTxt + '</span>';
                });
            },
            async getUsuarioInfo() {
                //TODO Retirar esse metodo e usar Vuex
                this.CURRENT_USER = await AuthService.getCurrentUser()
            },
        },
        mounted() {
            document.title = "Fiscalizações - JF Assessoria Rural"
            this.loading = true;
            this.getUsuarioInfo();
            this.loadFilter().then((filter) => {
                if(!this.$route.query.page){
                    this.$router.replace({query:{page:this.page}});
                    return;
                }else{
                    this.page = parseInt(this.$route.query.page);
                }
                if (filter) {
                    // TODO Montar o componente do filtro aqui
                    this.currentFilter = filter;
                    this.getList(this.currentFilter);
                } else {
                    this.getList(this.currentFilter);
                }
            });
        }
    };
</script>

<style>
    span.highlight {
        background-color: yellow;
    }
</style>


<style scoped>
    .v-list-item-finalizado{
        background-color: #f9f9f9;
    }
    .v-list-item-finalizado .v-list-item__title,
    .v-list-item-finalizado .v-list-item__subtitle,
    .v-list-item-finalizado .v-list-item__action-text{
        opacity: 0.7;
    }
    .v-list-item-finalizado::after{
        width: 6px;
        content: "";
        display: inline-block;
        height: 92px;
        margin-right: -16px;
        margin-left: 16px;
    }
    .finalizado-regular::after{
        background-color: #4CAF50;
    }
    .finalizado-irregular::after{
        background-color: #f31b1b;
    }
</style>
