import gql from 'graphql-tag';

export class CidadeQueries {
    public static findCidades = gql`query FindCidades($arg: String!) {
        cidades: findCidades(arg: $arg){
            id
            codigo
            nome
            estado{
                sigla
            }
        }
    }`;
}
