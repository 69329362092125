import gql from 'graphql-tag';

export class FinalidadeQueries {
    public static getAll = gql`query {
        finalidades{
            id
            codigo
            descricao
        }
    }`;
}
