import {Financiamento} from '@/models/Financiamento';
import {Foto} from '@/models/Foto';
import {Usuario} from '@/models/Usuario';
import {Tipo} from '@/models/Tipo';
import {Servico} from '@/models/Servico';

export class Fiscalizacao {

    public dataLimite: string = new Date().toLocaleDateString();
    public financiamento: Financiamento = new Financiamento();
    public fotos: Foto[] = [];
    public dataFiscalizacao: string;
    public supervisedBy: Usuario;
    public tipo: Tipo;
    public distanciaPercorrida: number;
    public servico: Servico;
}
