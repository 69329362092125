<template>
    <v-dialog v-model="dialog" v-if="dialog" scrollable persistent max-width="600">
        <v-card>
            <v-card-title>
                <span class="headline">Editar Fiscalização</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <!--Tipo-->
                    <v-row v-if="!fiscalizacaoTemp.tipo.id">
                        <v-col>
                            <v-select v-model="fiscalizacao.tipo.id"
                                      :items="tipos" item-text="nome" item-value="id"
                                      label="Tipo" single-line :rules="requiredRules" required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <!--Mutuário-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.mutuario">
                        <v-col>
                            <v-text-field v-model="fiscalizacao.financiamento.mutuario" label="Mutuário"
                                          placeholder=" " :rules="requiredRules" required></v-text-field>
                        </v-col>
                    </v-row>
                    <!--Operação/Digito - Cédula-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.operacao || !fiscalizacaoTemp.financiamento.cedula">
                        <!--Operação-->
                        <v-col v-if="!fiscalizacaoTemp.financiamento.operacao">
                            <v-text-field v-model="fiscalizacao.financiamento.operacao" label="Operação"
                                          maxlength="9" placeholder=" " :rules="requiredRules" v-mask="'#########'"
                                          required></v-text-field>
                        </v-col>
                        <!--Digito-->
                        <v-col cols="3" v-if="!fiscalizacaoTemp.financiamento.operacao">
                            <v-text-field v-model="fiscalizacao.financiamento.operacaoDigito" label="Digito"
                                          maxlength="1" placeholder=" " v-mask="'#'">
                            </v-text-field>
                        </v-col>
                        <!--Cédula-->
                        <v-col v-if="!fiscalizacaoTemp.financiamento.cedula">
                            <v-text-field v-model="fiscalizacao.financiamento.cedula" label="Cédula" maxlength="9"
                                          placeholder=" " :rules="requiredRules" v-mask="'#########'" required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--Data Cedula/Data Limite/Data Vencimento-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.dataCedula || !fiscalizacaoTemp.dataLimite || !fiscalizacaoTemp.financiamento.dataVencimento">
                        <!--Data Cedula-->
                        <v-col v-if="!fiscalizacaoTemp.financiamento.dataCedula">
                            <v-text-field v-model="fiscalizacao.financiamento.dataCedula" label="Data Cédula"
                                          v-mask="'##/##/####'" placeholder=" " :rules="requiredRules" required>
                            </v-text-field>
                        </v-col>
                        <!--Data Limite-->
                        <v-col v-if="!fiscalizacaoTemp.dataLimite">
                            <v-text-field v-model="fiscalizacao.dataLimite" label="Data Limite"
                                          v-mask="'##/##/####'" placeholder=" " :rules="requiredRules" required>
                            </v-text-field>
                        </v-col>
                        <!--Data Vencimento-->
                        <v-col v-if="!fiscalizacaoTemp.financiamento.dataVencimento">
                            <v-text-field v-model="fiscalizacao.financiamento.dataVencimento" label="Data Vencimento"
                                          v-mask="'##/##/####'" placeholder=" " :rules="requiredRules" required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <!--Valores-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.valor || !fiscalizacaoTemp.financiamento.valorRecursosProprios || !fiscalizacaoTemp.financiamento.valorTotalGarantias">
                        <!--Valor Financiado-->
                        <v-col v-if="!fiscalizacaoTemp.financiamento.valor">
                            <v-text-field label="Valor Financiado" prefix="R$" placeholder=" " ref="valorFinanciado"
                                          v-model="fiscalizacao.financiamento.valor"
                                          v-money="money"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                        <!--Valor de Recursos Próprios-->
                        <v-col v-if="!fiscalizacaoTemp.financiamento.valorRecursosProprios">
                            <v-text-field label="Valor de Recursos Próprios" prefix="R$" placeholder=" " ref="valorRecursosProprios"
                                          v-model="fiscalizacao.financiamento.valorRecursosProprios"
                                          v-money="money"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                        <!--Valor Total das Garantias-->
                        <v-col v-if="!fiscalizacaoTemp.financiamento.valorTotalGarantias">
                            <v-text-field label="Valor Total das Garantias" prefix="R$" placeholder=" " ref="valorTotalGarantias"
                                          v-model="fiscalizacao.financiamento.valorTotalGarantias"
                                          v-money="money"
                                          :rules="requiredRules" required></v-text-field>
                        </v-col>
                    </v-row>
                    <!--Agência-->
                    <v-row v-if="!agenciaIdTemp">
                        <v-col>
                            <v-autocomplete v-model="agenciaId" label="Agência" placeholder=" "
                                            :items="agencias" :loading="isAgenciasLoading"
                                            :search-input.sync="searchAgenciasValue"
                                            item-text="nome" item-value="id" auto-select-first
                                            hide-no-data :rules="requiredRules" eager>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <!--Origem Recursos-->
                    <v-row v-if="!origemRecursosIdTemp">
                        <v-col>
                            <v-autocomplete v-model="origemRecursosId"
                                            label="Origem dos Recursos" placeholder=" " :items="origemRecursos"
                                            item-text="descricao" item-value="id" :rules="requiredRules">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <!--Finalidade-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.finalidade.id">
                        <v-col>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-autocomplete v-model="fiscalizacao.financiamento.finalidade.id"
                                                    label="Finalidade" placeholder=" " :items="finalidades"
                                                    item-text="descricao" item-value="id" :rules="requiredRules">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!--Empreendimento-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.empreendimento.id">
                        <v-col>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-autocomplete v-model="fiscalizacao.financiamento.empreendimento.id"
                                                    label="Empreendimento" placeholder=" " :items="empreendimentos"
                                                    item-text="descricao" item-value="id" :rules="requiredRules">
                                    </v-autocomplete>
                                </v-col>
                                <!--<v-col cols="2" class="py-0 align-self-center">
                                    <v-btn text icon color="primary" @click="newEmpreendimentoDialog">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>-->
                            </v-row>
                        </v-col>
                    </v-row>
                    <!--Propriedade-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.area.nome">
                        <v-col>
                            <v-text-field v-model="fiscalizacao.financiamento.area.nome" label="Propriedade"
                                          placeholder=" " ></v-text-field>
                        </v-col>
                    </v-row>
                    <!--Município-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.area.localizacao.cidade.id">
                        <v-col>
                            <v-autocomplete v-model="fiscalizacao.financiamento.area.localizacao.cidade.id" label="Município" placeholder=" "
                                            :items="cidades" :loading="isCidadesLoading"
                                            :search-input.sync="searchCidadesValue"
                                            item-text="nome" item-value="id" auto-select-first
                                            hide-no-data :rules="requiredRules" eager>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <!--Rota de Acesso-->
                    <v-row v-if="!fiscalizacaoTemp.financiamento.area.roteiroDeAcesso">
                        <v-col>
                            <v-text-field v-model="fiscalizacao.financiamento.area.roteiroDeAcesso" label="Rota de Acesso"
                                          placeholder=" " ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-expansion-panels flat hover focusable :value="expansionPanel">
                        <v-expansion-panel>
                            <v-expansion-panel-header v-if="hasEmptyField(fiscalizacaoTemp)" v-slot="{ open }">
                                <span v-if="open">Mostrar menos</span>
                                <span v-else>Mostrar mais</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <!--Tipo-->
                                <v-row v-if="fiscalizacao.tipo.id">
                                    <v-col>
                                        <v-select v-model="fiscalizacao.tipo.id"
                                                  :items="tipos" item-text="nome" item-value="id"
                                                  label="Tipo" single-line :rules="requiredRules" required
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <!--Mutuário-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.mutuario">
                                    <v-col>
                                        <v-text-field v-model="fiscalizacao.financiamento.mutuario" label="Mutuário"
                                                      placeholder=" " :rules="requiredRules" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <!--Operação/Digito - Cédula-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.operacao || fiscalizacaoTemp.financiamento.cedula">
                                    <!--Operação-->
                                    <v-col v-if="fiscalizacaoTemp.financiamento.operacao">
                                        <v-text-field v-model="fiscalizacao.financiamento.operacao" label="Operação"
                                                      maxlength="9" placeholder=" " :rules="requiredRules" v-mask="'#########'"
                                                      required></v-text-field>
                                    </v-col>
                                    <v-col cols="3" v-if="fiscalizacaoTemp.financiamento.operacao">
                                        <v-text-field v-model="fiscalizacao.financiamento.operacaoDigito" label="Digito"
                                                      maxlength="1" placeholder=" " v-mask="'#'">
                                        </v-text-field>
                                    </v-col>
                                    <!--Cédula-->
                                    <v-col v-if="fiscalizacaoTemp.financiamento.cedula">
                                        <v-text-field v-model="fiscalizacao.financiamento.cedula" label="Cédula" maxlength="9"
                                                      placeholder=" " :rules="requiredRules" v-mask="'#########'" required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <!--Data Cedula/Data Limite-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.dataCedula || fiscalizacaoTemp.dataLimite || fiscalizacaoTemp.financiamento.dataVencimento">
                                    <!--Data Cedula-->
                                    <v-col v-if="fiscalizacaoTemp.financiamento.dataCedula">
                                        <v-text-field v-model="fiscalizacao.financiamento.dataCedula" label="Data Cédula"
                                                      v-mask="'##/##/####'" placeholder=" " :rules="requiredRules" required>
                                        </v-text-field>
                                    </v-col>
                                    <!--Data Limite-->
                                    <v-col v-if="fiscalizacaoTemp.dataLimite">
                                        <v-text-field v-model="fiscalizacao.dataLimite" label="Data Limite"
                                                      v-mask="'##/##/####'" placeholder=" " :rules="requiredRules" required>
                                        </v-text-field>
                                    </v-col>
                                    <!--Data Vencimento-->
                                    <v-col v-if="fiscalizacaoTemp.financiamento.dataVencimento">
                                        <v-text-field v-model="fiscalizacao.financiamento.dataVencimento" label="Data Vencimento"
                                                      v-mask="'##/##/####'" placeholder=" " :rules="requiredRules" required>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <!--Valores-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.valor || fiscalizacaoTemp.financiamento.valorRecursosProprios || fiscalizacaoTemp.financiamento.valorTotalGarantias">
                                    <!--Valor Financiado-->
                                    <v-col v-if="fiscalizacaoTemp.financiamento.valor">
                                        <v-text-field label="Valor Financiado" prefix="R$" placeholder=" " ref="valorFinanciado"
                                                      v-model="fiscalizacao.financiamento.valor"
                                                      v-money="money"
                                                      :rules="requiredRules" required></v-text-field>
                                    </v-col>
                                    <!--Valor de Recursos Próprios-->
                                    <v-col v-if="fiscalizacaoTemp.financiamento.valorRecursosProprios">
                                        <v-text-field label="Valor de Recursos Próprios" prefix="R$" placeholder=" " ref="valorRecursosProprios"
                                                      v-model="fiscalizacao.financiamento.valorRecursosProprios"
                                                      v-money="money"
                                                      :rules="requiredRules" required></v-text-field>
                                    </v-col>
                                    <!--Valor Total das Garantias-->
                                    <v-col v-if="fiscalizacaoTemp.financiamento.valorTotalGarantias">
                                        <v-text-field label="Valor Total das Garantias" prefix="R$" placeholder=" " ref="valorTotalGarantias"
                                                      v-model="fiscalizacao.financiamento.valorTotalGarantias"
                                                      v-money="money"
                                                      :rules="requiredRules" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <!--Agência-->
                                <v-row v-if="agenciaIdTemp">
                                    <v-col>
                                        <v-autocomplete v-model="agenciaId" label="Agência" placeholder=" "
                                                        :items="agencias" :loading="isAgenciasLoading"
                                                        :search-input.sync="searchAgenciasValue"
                                                        item-text="nome" item-value="id" auto-select-first
                                                        hide-no-data :rules="requiredRules" eager>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <!--Origem Recursos-->
                                <v-row v-if="origemRecursosIdTemp">
                                    <v-col>
                                        <v-autocomplete v-model="origemRecursosId"
                                                        label="Origem dos Recursos" placeholder=" " :items="origemRecursos"
                                                        item-text="descricao" item-value="id" :rules="requiredRules">
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <!--Finalidade-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.finalidade.id">
                                    <v-col>
                                        <v-row>
                                            <v-col cols="12" class="py-0">
                                                <v-autocomplete v-model="fiscalizacao.financiamento.finalidade.id"
                                                                label="Finalidade" placeholder=" " :items="finalidades"
                                                                item-text="descricao" item-value="id" :rules="requiredRules">
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <!--Empreendimento-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.empreendimento.id">
                                    <v-col>
                                        <v-row>
                                            <v-col cols="12" class="py-0">
                                                <v-autocomplete v-model="fiscalizacao.financiamento.empreendimento.id"
                                                                label="Empreendimento" placeholder=" " :items="empreendimentos"
                                                                item-text="descricao" item-value="id" :rules="requiredRules">
                                                </v-autocomplete>
                                            </v-col>
                                            <!--<v-col cols="2" class="py-0 align-self-center">
                                                <v-btn text icon color="primary" @click="newEmpreendimentoDialog">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>-->
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <!--Propriedade-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.area.nome">
                                    <v-col>
                                        <v-text-field v-model="fiscalizacao.financiamento.area.nome" label="Propriedade"
                                                      placeholder=" " ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!--Município-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.area.localizacao.cidade.id">
                                    <v-col>
                                        <v-autocomplete v-model="fiscalizacao.financiamento.area.localizacao.cidade.id" label="Município" placeholder=" "
                                                        :items="cidades" :loading="isCidadesLoading"
                                                        :search-input.sync="searchCidadesValue"
                                                        item-text="nome" item-value="id" auto-select-first
                                                        hide-no-data :rules="requiredRules" eager>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <!--Rota de Acesso-->
                                <v-row v-if="fiscalizacaoTemp.financiamento.area.roteiroDeAcesso">
                                    <v-col>
                                        <v-text-field v-model="fiscalizacao.financiamento.area.roteiroDeAcesso" label="Rota de Acesso"
                                                      placeholder=" " ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save(fiscalizacao)">
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>

        <finalidade-dialog ref="finalidadeDialog" @saved="onFinalidadeSaved" />
        <empreendimento-dialog ref="empreendimentoDialog" @saved="onEmpreendimentoSaved" />
    </v-dialog>
</template>

<script>
    // @ts-ignore
    import FinalidadeDialog from './FinalidadeDialog';
    // @ts-ignore
    import EmpreendimentoDialog from './EmpreendimentoDialog';
    import {FinalidadeQueries} from '../../../queries/FinalidadeQueries';
    import {AgenciaQueries} from '../../../queries/AgenciaQueries';
    import {EmpreendimentoQueries} from '../../../queries/EmpreendimentoQueries';
    import {Fiscalizacao} from '../../../models/Fiscalizacao';
    import {FiscalizacaoQueries} from '../../../queries/FiscalizacaoQueries';
    import {OrigemRecusoQueries} from '../../../queries/OrigemRecusoQueries';
    import numeral from 'numeral';
    import {CidadeQueries} from '../../../queries/CidadeQueries';
    import {Localizacao} from '../../../models/Localizacao';
    import {Tipo} from "../../../models/Tipo";

    export default {
        name: 'NewDialog',
        components: {
            EmpreendimentoDialog,
            FinalidadeDialog,
        },
        data() {
            return {
                form1Valid: true,
                expansionPanel: false,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                fiscalizacao: new Fiscalizacao(),
                fiscalizacaoTemp: null,
                dialog: false,
                finalidades: [],
                agencias: [],
                cidades: [],
                isAgenciasLoading: false,
                isCidadesLoading: false,
                searchAgenciasValue: null,
                searchCidadesValue: null,
                empreendimentos: [],
                origemRecursos: [],
                /*fiscalizacaoTipos: [
                    {value: 1, label: "CUSTEIO PECUÁRIO"},
                    {value: 2, label: "CUSTEIO AGRÍCOLA"},
                ]*/
                tipos: [],
                agenciaId: null,
                agenciaIdTemp: null,
                origemRecursosId: null,
                origemRecursosIdTemp: null,
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false /* doesn't work with directive */
                },
            };
        },
        watch: {
            searchAgenciasValue(value) {
                if (value !== null) {
                    /*if(!isNaN(parseInt(value))){
                        value = parseInt(value);
                    }
                    console.log("searchAgencias", value)*/
                    this.searchAgencias(value);
                }
            },
            searchCidadesValue(value) {
                if (value !== null) {
                    this.searchCidades(value);
                }
            },
        },
        mounted() {
            numeral.locale('pt-br');
        },
        methods: {
            openDialog(fiscalizacao) {
                this.dialog = true;
                this.fiscalizacao = this._.cloneDeep(fiscalizacao);

                this.fiscalizacao.financiamento.valor = numeral(this.fiscalizacao.financiamento.valor).format('0.00').replace(',','.');
                this.fiscalizacao.financiamento.valorRecursosProprios = numeral(this.fiscalizacao.financiamento.valorRecursosProprios).format('0.00').replace(',','.');
                this.fiscalizacao.financiamento.valorTotalGarantias = numeral(this.fiscalizacao.financiamento.valorTotalGarantias).format('0.00').replace(',','.');

                this.fiscalizacao.dataLimite = new Date(fiscalizacao.dataLimite).toLocaleDateString();
                this.fiscalizacao.financiamento.dataCedula =
                    new Date(fiscalizacao.financiamento.dataCedula).toLocaleDateString();
                this.fiscalizacao.financiamento.dataVencimento =
                    new Date(fiscalizacao.financiamento.dataVencimento).toLocaleDateString();
                /*this.fiscalizacao.financiamento.agencia.nome =
                    this.fiscalizacao.financiamento.agencia.codigo
                    + " - " + this.fiscalizacao.financiamento.agencia.nome*/
                if(this.fiscalizacao.financiamento.agencia){
                    this.agenciaId = fiscalizacao.financiamento.agencia.id;
                    this.agenciaIdTemp = this.agenciaId;
                    this.searchAgencias(this.fiscalizacao.financiamento.agencia.codigo);
                }

                if(this.fiscalizacao.financiamento.origemRecursos){
                    this.origemRecursosId = this.fiscalizacao.financiamento.origemRecursos.id;
                    this.origemRecursosIdTemp = this.origemRecursos;
                }

                // console.log(this.fiscalizacao.financiamento.area.localizacao)
                if (this.fiscalizacao.financiamento.area.localizacao == null) {
                    this.fiscalizacao.financiamento.area.localizacao = new Localizacao();
                } else {
                    this.searchCidades(this.fiscalizacao.financiamento.area.localizacao.cidade.codigo);
                }

                if(this.fiscalizacao.tipo == null){
                    this.fiscalizacao.tipo = new Tipo();
                }
                this.expansionPanel = this.hasEmptyField(this.fiscalizacao) ? null : 0;

                this.getFinalidades();
                this.getEmpreendimentos();
                this.getOrigemRecursos();
                this.getTipos();

                this.fiscalizacaoTemp = this._.cloneDeep(this.fiscalizacao);
            },
            closeDialog() {
                this.dialog = false;
                // this.$refs.form1.reset();
                this.$refs.form1.resetValidation();

            },
            hasEmptyField(fiscalizacao){
                if(!fiscalizacao.tipo.id){
                    return true;
                }
                if(!fiscalizacao.financiamento.operacao){
                    return true;
                }
                if(!fiscalizacao.financiamento.cedula){
                    return true;
                }
                if(!this.agenciaId){
                    return true;
                }
                if(!fiscalizacao.financiamento.dataCedula){
                    return true;
                }
                if(!fiscalizacao.dataLimite){
                    return true;
                }
                if(!this.origemRecursosId){
                    return true;
                }
                if(!fiscalizacao.financiamento.finalidade.id){
                    return true;
                }
                if(!fiscalizacao.financiamento.empreendimento.id){
                    return true;
                }

                if(!this.fiscalizacao.financiamento.valor){
                    return true;
                }
                if(!this.fiscalizacao.financiamento.valorRecursosProprios){
                    return true;
                }
                if(!this.fiscalizacao.financiamento.valorTotalGarantias){
                    return true;
                }
                if(!this.fiscalizacao.financiamento.mutuario){
                    return true;
                }
                if(!this.fiscalizacao.financiamento.area.nome){
                    return true;
                }
                if(!this.fiscalizacao.financiamento.area.roteiroDeAcesso){
                    return true;
                }
                if(!this.fiscalizacao.financiamento.area.localizacao.cidade.id){
                    return true;
                }
                if(!this.fiscalizacao.financiamento.dataVencimento){
                    return true;
                }
                return false;
            },
            save(fiscalizacao) {
                if(!this.$refs.form1.validate()){
                    return false
                }
                this.$apollo.mutate({
                    mutation: FiscalizacaoQueries.update,
                    variables: {
                        id: this.fiscalizacao.id,
                        fiscalizacao: this.parseRequest(fiscalizacao),
                    },
                }).then((result) => {
                    this.$emit('updated', Object.assign({}, result.data.updateFiscalizacao));
                    this.closeDialog();
                });
            },
            parseRequest(fiscalizacao) {
                const fiscalizacaoParsed = this._.cloneDeep(fiscalizacao);

                delete fiscalizacaoParsed.id;
                delete fiscalizacaoParsed.status;
                delete fiscalizacaoParsed.regular;
                delete fiscalizacaoParsed.supervisedBy;
                delete fiscalizacaoParsed.tipo.nome;
                delete fiscalizacaoParsed.financiamento.id;
                fiscalizacaoParsed.financiamento.agencia = {id: this.agenciaId};
                fiscalizacaoParsed.financiamento.origemRecursos = {id: this.origemRecursosId};
                delete fiscalizacaoParsed.financiamento.area.localizacao.cidade.estado;
                delete fiscalizacaoParsed.financiamento.objetos;
                delete fiscalizacaoParsed.financiamento.garantias;
                delete fiscalizacaoParsed.fotos;
                delete fiscalizacaoParsed.arquivos;
                delete fiscalizacaoParsed.contatos;
                delete fiscalizacaoParsed.pontoPartida;
                delete fiscalizacaoParsed.dataFiscalizacao;
                delete fiscalizacaoParsed.dataArquivoBanco;
                delete fiscalizacaoParsed.emailEnviadoAt;
                delete fiscalizacaoParsed.lembretes;
                delete fiscalizacaoParsed.emailHistorico;
                delete fiscalizacaoParsed.solicitacoesProrrogacao;
                delete fiscalizacaoParsed.fiscalizacoesMesmoMutuario;

                fiscalizacaoParsed.dataLimite = this.convertStringToDate(fiscalizacao.dataLimite).getTime();

                fiscalizacaoParsed.financiamento.dataCedula =
                    this.convertStringToDate(fiscalizacao.financiamento.dataCedula).getTime();
                fiscalizacaoParsed.financiamento.dataVencimento =
                    this.convertStringToDate(fiscalizacao.financiamento.dataVencimento).getTime();

                fiscalizacaoParsed.financiamento.valor =
                    this.convertMoneyToFloat(fiscalizacao.financiamento.valor);
                fiscalizacaoParsed.financiamento.valorTotalGarantias =
                    this.convertMoneyToFloat(fiscalizacao.financiamento.valorTotalGarantias);
                fiscalizacaoParsed.financiamento.valorRecursosProprios =
                    this.convertMoneyToFloat(fiscalizacao.financiamento.valorRecursosProprios);
                return fiscalizacaoParsed;
            },
            convertStringToDate(dateString) {
                const dateArr = dateString.split('/');

                return new Date(dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2]);
            },
            convertMoneyToFloat(value) {
                if (!isNaN(value)) {
                    return value;
                }
                return parseFloat(value.replace(/\./g, '').replace(',', '.'));
            },
            convertNumberToFixed(value) {
                // return number;
                return numeral(value).format('0.0,00');
                // number = parseFloat(number);
                // return number.toFixed(Math.max(((number+'').split(".")[1]||"").length, 2));
            },
            getFinalidades() {
                this.$apollo.query({query: FinalidadeQueries.getAll}).then((result) => {
                    this.finalidades = result.data.finalidades.map((finalidade) => {
                        if(finalidade.codigo){
                            finalidade.descricao = finalidade.codigo + ' - ' + finalidade.descricao;
                        }
                        return finalidade;
                    });
                });
            },
            searchAgencias(value) {
                this.isAgenciasLoading = true;
                this.$apollo.query({
                    query: AgenciaQueries.findAgencias,
                    variables: {arg: value, limit: 10},
                }).then((result) => {
                    this.agencias = result.data.agencias.map((agencia) => {
                        agencia.nome = agencia.codigo + ' - ' + agencia.nome;

                        return agencia;
                    });
                }).finally(() => {
                    this.isAgenciasLoading = false;
                });
            },
            parseAgenciaEndereco(agencia) {
                let text = '';
                text += agencia.endereco;

                if (agencia.numero.trim().length > 0) {
                    text += ', ' + agencia.numero;
                }

                text += ' - ' + agencia.cidade.nome + '/' + agencia.cidade.estado.sigla;

                return text;
            },
            searchCidades(value) {
                this.isCidadesLoading = true;
                this.$apollo.query({
                    query: CidadeQueries.findCidades,
                    variables: {arg: value, limit: 10},
                }).then((result) => {
                    this.cidades = result.data.cidades.map((cidade) => {
                        cidade.nome = cidade.codigo + ' - ' + cidade.nome;

                        return cidade;
                    });
                }).finally(() => {
                    this.isCidadesLoading = false;
                });
            },
            getEmpreendimentos() {
                this.$apollo.query({query: EmpreendimentoQueries.getAll}).then((result) => {
                    this.empreendimentos = result.data.empreendimentos.map((empreendimento) => {
                        if(empreendimento.codigo){
                            empreendimento.descricao = empreendimento.codigo + ' - ' + empreendimento.descricao;
                        }
                        return empreendimento;
                    });
                });
            },
            getOrigemRecursos() {
                this.$apollo.query({query: OrigemRecusoQueries.getAll}).then((result) => {
                    this.origemRecursos = result.data.origemRecursos.map((origemRecurso) => {
                        origemRecurso.descricao = origemRecurso.codigo + ' - ' + origemRecurso.descricao;
                        return origemRecurso;
                    });
                });
            },
            getTipos() {
                this.$apollo.query({query: FiscalizacaoQueries.getTipos}).then((result) => {
                    this.tipos = result.data.tipos.map((tipo) => {
                        tipo.nome = tipo.nome.toUpperCase();
                        return tipo;
                    });
                });
            },
            newFinalidadeDialog() {
              this.$refs.finalidadeDialog.openDialog();
            },
            onFinalidadeSaved(finalidade) {
                // console.log('Finalidade criada', finalidade);
            },
            newEmpreendimentoDialog() {
                this.$refs.empreendimentoDialog.openDialog();
            },
            onEmpreendimentoSaved(empreendimento) {
                // console.log('Empreendimento criada', empreendimento);
            },


        },
    };
</script>

<style scoped>
    .v-stepper{
        box-shadow: none;
    }
    .v-expansion-panel-content{
        margin: 0 -24px -16px;
    }
</style>
